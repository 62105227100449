/* Import czcionki Audiowide */
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import './styles/flexbox';
@import './styles/buttons';
@import './styles/header';

:root {
    --white-color: #ffff;
    --text-color: #333;
    --box-shadow-color: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Reset i style globalne */
// Zmienne
$primary-font: 'Rale', sans-serif;
$primary-color: #4cae4b;
$background-color: #F6F6F6;
$text-color: #333;
$white-color: #fff;
$hover-color: #439c3c;
$box-shadow-color: rgba(0, 0, 0, 0.1);

* {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    box-sizing: border-box;
    line-height: 1;
    text-decoration: none;
}

body {
    font-family: 'Inter', sans-serif;
    font-family: 'Raleway', sans-serif;
    background-color: $background-color;
    color: $text-color;
    font-size: 1rem;
}

h1 {
    font-size: 4rem;

    @media(max-width: 900px) {
        font-size: 2.5rem;
    }
}

h2 {
    font-size: 3rem;
    margin-top: 2rem;
}

.audiowide {
    font-family: Audiowide;
}

.ooo-box{
    display: flex;
    text-align: center;
    justify-content: center;
    min-height: 30vh;
    align-items: center;
    gap: 1rem;
    width: 50vw;
    padding: 1rem;
    color: #1D951B;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 1.25rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 10000;
    font-size: 2rem;
    left: calc(50% - 25vw);
    top: calc(50% - 25vh);
    span {
        line-height: 1.5;
    }
    @media(max-width: 900px) {
        width: 90vw;
        left: calc(50% - 45vw);
        top: 10%;
    }
}

#home {
    justify-content: center;
    align-items: stretch;
    height: 100vh;
    padding-top: 60px;
    overflow: hidden;

    .image-container {
        position: relative;

        img {
            height: 100%;
            width: auto;
            min-width: 100%;
            object-fit: cover;
        }

        .intro-text {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            color: white;
            z-index: 100;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.5);
                /* Dostosuj kolor i nieprzezroczystość nakładki */
                z-index: -1;
            }

            .text-secondary {
                font-size: 2rem;
                margin-bottom: 2rem;
            }

        }

    }

    @media(max-width: 900px) {
        .image-container {
            height: 100vh;

            .intro-text {
                padding-inline: 2.5rem;

                h1 {
                    text-align: center;
                    line-height: 1.3;
                }
            }
        }


    }
}

.pricing {
    .intro-image {
        height: 385px;
        overflow: hidden;
        width: 100%;
        background: url(worksop.jpg) no-repeat center;
        position: relative;
    }

    @media(max-width: 900px) {
        .intro-image {
            height: 200px;
        }
    }
}

.category-title {
    font-size: 2.5rem;
}

.service-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 900px) {
        flex-direction: column;
        gap: 2rem;
    }
}

.color-white{
    color: var(--white-color)!important;
}

.repairs {
    flex-direction: column;

    .other {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 500;
    }

    .subcategory-title {
        background: #1D951B;
        padding: 2rem 2.25rem;
        color: #fff;
        border-top-left-radius: 1.5rem;
        border-top-right-radius: 1.5rem;
        text-align: center;
        font-size: 1.5rem;
        letter-spacing: 2px;
        font-weight: 700;
    }

    .repairs-content {
        background-color: #fff;
        padding: 1rem 2.25rem 2.625rem 2.25rem;
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
        flex-wrap: wrap;
        gap: 1.25rem;
        justify-content: space-between;
    }

    @media(max-width: 900px) {
        .repairs-content {
            flex-direction: column;
            padding: 1rem 1rem 2rem 1rem;
        }
    }
}


.col {
    display: flex;
    flex-shrink: 0;
    width: 32%;
    flex-direction: column;

    h3 {
        margin-bottom: 1rem;
        display: flex;
        color: #1B961B;
        border-bottom: 1px solid #D6D6D6;
        letter-spacing: 2px;
        font-weight: 600;
        line-height: 1.3;
    }

    ul {
        display: flex;
        flex-direction: column;
    }

    .offer-list {
        li {
            height: 2.50rem;
            background: #FAFAFA;
            padding: 0.25rem;

            &:nth-child(odd) {
                background: #EFEFEF;
            }
        }
    }

    li {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        font-size: 0.875rem;
    }

    @media(max-width:900px) {
        width: 100%;
    }
}

.card {
    border-radius: 1.25rem;
    overflow: hidden;
    width: 23%;

    .card-header {
        padding: 2.225rem 2rem;
        text-align: center;
        gap: 0.875rem;

        &.primary {
            background-color: #1D951B;
        }

        &.secondary {
            background-color: #D3852F;
        }

        .top-line {
            color: #fff;

            span,
            h3 {
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            span {
                font-size: 0.75rem;
            }

            h3 {
                font-size: 1.125rem;
            }
        }
    }

    .price {
        justify-content: center;
        gap: 0.75rem;
        align-items: center;

        .separator,
        .lead {
            font-size: 1.7rem;
            font-weight: 700;
            letter-spacing: 3px;
        }

        .asterix {
            letter-spacing: 1px;
            font-size: 0.65rem;
            font-weight: 700;
        }
    }

    .card-content {
        padding: 2.225rem 1.25rem;
        background-color: #FBFBFB;

        ul {
            gap: 0.75rem;

            &.primary {
                li {
                    &:before {
                        border-color: #1D951B;
                    }
                }
            }
        }

        li {
            padding-left: 1.5rem;
            font-size: 0.85rem;
            letter-spacing: 1px;
            color: #000;
            font-weight: 500;
            display: flex;
            position: relative;

            &:before {
                content: "";
                width: 0.35rem;
                height: 0.35rem;
                border-radius: 50%;
                border: 2px solid;
                position: absolute;
                left: 0;
                display: flex;
                align-self: center;
            }

        }
    }

    @media(max-width: 900px) {
        width: 100%;

        .card-content {
            padding: 2rem 1rem;
        }
    }
}


.line {
    .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 51px;
        height: 51px;
        border-radius: 50%;
        background: #1D951B;
        font-size: 1.5rem;
        color: #fff;
        font-family: Audiowide;
        flex-shrink: 0;
        position: relative;

        &::after {
            content: "";
            height: 27px;
            width: 27px;
            border-radius: 50%;
            position: absolute;
            background: #1D951B;
        }

        &::before {
            content: "";
            height: 17px;
            width: 17px;
            border-radius: 50%;
            position: absolute;
            background: #1D951B;
        }
    }

    .text {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5;

        span {
            color: #D3852F;
        }
    }

    @media(max-width: 900px) {
        .circle {

            &::after,
            &::before {
                display: none;
            }
        }

    }
}

.bikes {
    .intro-image {
        height: 389px;
        overflow: hidden;
        width: 100%;
        background: url(bikes.jpg) no-repeat center;
        position: relative;
    }

    .question {
        font-size: 2rem;
        color: #1B961B;
        font-weight: 700;
        justify-content: center;
    }

    .points-container {
        width: 80%;
        margin-left: auto;

        .line {
            &:nth-child(odd) {
                padding-left: 1.75rem;

                .circle {
                    &::after {
                        right: -5%;
                        bottom: -70%;
                    }

                    &::before {
                        right: 35%;
                        bottom: -130%;
                    }
                }
            }

            &:nth-child(even) {

                .circle {
                    &::after {
                        left: 15%;
                        bottom: -80%;
                    }

                    &::before {
                        left: 55%;
                        bottom: -130%;
                    }
                }
            }

            &:last-child {
                .circle {

                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width:900px) {
        .intro-image {
            height: 200px;
        }

        .points-container {
            width: 100%;
            gap: 2rem !important;

            .line {
                &:nth-child(odd) {
                    padding-left: 0;
                }
            }
        }
    }
}

.title-section {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 100;
    font-size: 2.5rem;
    font-family: Audiowide;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    @media(max-width: 900px) {
        padding-inline: 2rem;
        font-size: 2rem;
        text-align: center;
    }
}

.transport {
    .intro-image {
        height: 462px;
        overflow: hidden;
        width: 100%;
        background: url(transport.jpg) no-repeat center;
        position: relative;
    }

    @media(max-width: 900px) {
        .intro-image {
            height: 200px;
        }
    }
}

.block {
    padding-block: 3.5rem;
    flex: 1;

    .block-title {
        font-size: 2.5rem;
        font-weight: 700;
        text-align: center;
    }

    p {
        font-size: 1.5rem;
        text-align: center;
        font-weight: 500;
        line-height: 1.75rem;
        padding-inline: min(6%, 150px);
        flex-grow: 1;
        align-items: center;
    }

    &.green-block {
        background: #1B961B;

        .block-title {
            color: #fff;
        }

        p {
            color: #fff;
        }
    }

    &.whtie-block {
        background: #fff;

        .block-title {
            color: #1B961B;
        }

        p {
            color: #787878;
        }
    }
}

.contact {
    padding-block: 3rem;

    .map-wrapper {
        width: auto;

        img {
            display: flex;
            width: 100%;
        }
    }

    @media(max-width: 900px) {
        .contact-mobile-gap {
            gap: 2rem;
        }
    }
}

.dark-part {
    background: #282828;

    .title {
        color: #fff;
        text-align: center;
        font-size: 2rem;
        font-weight: 600;
    }

    .sub-title {
        color: #D6D6D6;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
    }

    p {
        color: #818181;
        font-size: 1.1rem;
        text-align: center;
    }
}

@media (max-width: 900px) {
    .flex-container {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    header ul {
        flex-direction: column;
    }

    #home .intro-image {
        display: none;
    }

    .full-height-section {
        min-height: 50vh;
    }
}