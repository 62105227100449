@import "https://fonts.googleapis.com/css2?family=Audiowide&display=swap";
.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-1 {
  flex: 1;
}

.w-50 {
  width: 50%;
}

@media (width <= 900px) {
  .w-50 {
    width: 100%;
  }
}

.main-container {
  width: 80%;
  margin: auto;
}

@media (width <= 900px) {
  .main-container {
    width: 100%;
    padding-inline: 2rem;
  }
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

@media (width <= 900px) {
  .m-column-direction {
    flex-direction: column;
  }
}

.mobile-only {
  display: none;
}

@media (width <= 900px) {
  .mobile-only {
    display: flex;
  }
}

.gap-1 {
  gap: .25rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.gap-5 {
  gap: 2rem !important;
}

.p-5 {
  padding: 2rem;
}

.mb-8 {
  margin-bottom: 3.5rem;
}

.py-9 {
  padding-block: 4rem;
}

.pb-9 {
  padding-bottom: 4rem;
}

.mb-9 {
  margin-bottom: 4rem;
}

.gap-10 {
  gap: 4.5rem !important;
}

.py-10 {
  padding-block: 4.5rem;
}

.button {
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  border: none;
  border-radius: 50vw;
  outline: none;
  padding: .875rem 2.5rem;
  font-size: .875rem;
  font-weight: 600;
}

.button.main {
  color: #fff;
  background-color: #1d951b;
}

.button.secondary {
  color: #1d951b;
  background-color: #fff;
}

.button:hover {
  cursor: pointer;
}

header {
  background-color: var(--white-color);
  box-shadow: var(--box-shadow-color);
  z-index: 1000;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  position: fixed;
  top: 0;
}

header nav {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
}

header nav ul {
  gap: 1.5rem;
}

header nav li a {
  color: var(--text-color);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
}

header nav li a.active {
  color: #1d951b;
}

header .logo img {
  width: 100px;
}

.menu {
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.hamburger span {
  background-color: #333;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  display: block;
}

.hamburger:focus {
  outline: none;
}

@media (width <= 900px) {
  .hamburger {
    display: block;
  }

  header nav {
    justify-content: space-between;
  }

  header ul {
    flex-direction: column;
  }

  .menu {
    background-color: var(--white-color);
    flex-direction: column;
    width: 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .menu li {
    text-align: center;
    padding: 10px 0;
  }

  .menu.active {
    box-shadow: var(--box-shadow-color);
    padding-bottom: 2rem;
    display: flex;
  }
}

:root {
  --white-color: #fff;
  --text-color: #333;
  --box-shadow-color: 0 4px 8px #0000001a;
}

* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  padding: 0;
  line-height: 1;
  text-decoration: none;
  list-style: none;
}

body {
  color: #333;
  background-color: #f6f6f6;
  font-family: Raleway, sans-serif;
  font-size: 1rem;
}

h1 {
  font-size: 4rem;
}

@media (width <= 900px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  margin-top: 2rem;
  font-size: 3rem;
}

.audiowide {
  font-family: Audiowide;
}

.ooo-box {
  text-align: center;
  color: #1d951b;
  z-index: 10000;
  background: #fffc;
  border-radius: 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 50vw;
  min-height: 30vh;
  padding: 1rem;
  font-size: 2rem;
  display: flex;
  position: absolute;
  top: calc(50% - 25vh);
  left: calc(50% - 25vw);
  box-shadow: 0 4px 8px #0000001a;
}

.ooo-box span {
  line-height: 1.5;
}

@media (width <= 900px) {
  .ooo-box {
    width: 90vw;
    top: 10%;
    left: calc(50% - 45vw);
  }
}

#home {
  justify-content: center;
  align-items: stretch;
  height: 100vh;
  padding-top: 60px;
  overflow: hidden;
}

#home .image-container {
  position: relative;
}

#home .image-container img {
  object-fit: cover;
  width: auto;
  min-width: 100%;
  height: 100%;
}

#home .image-container .intro-text {
  color: #fff;
  z-index: 100;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  inset: 0;
}

#home .image-container .intro-text:after {
  content: "";
  z-index: -1;
  background-color: #00000080;
  position: absolute;
  inset: 0;
}

#home .image-container .intro-text .text-secondary {
  margin-bottom: 2rem;
  font-size: 2rem;
}

@media (width <= 900px) {
  #home .image-container {
    height: 100vh;
  }

  #home .image-container .intro-text {
    padding-inline: 2.5rem;
  }

  #home .image-container .intro-text h1 {
    text-align: center;
    line-height: 1.3;
  }
}

.pricing .intro-image {
  background: url("worksop.77ac0ef7.jpg") center no-repeat;
  width: 100%;
  height: 385px;
  position: relative;
  overflow: hidden;
}

@media (width <= 900px) {
  .pricing .intro-image {
    height: 200px;
  }
}

.category-title {
  font-size: 2.5rem;
}

.service-cards {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

@media (width <= 900px) {
  .service-cards {
    flex-direction: column;
    gap: 2rem;
  }
}

.color-white {
  color: var(--white-color) !important;
}

.repairs {
  flex-direction: column;
}

.repairs .other {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}

.repairs .subcategory-title {
  color: #fff;
  text-align: center;
  letter-spacing: 2px;
  background: #1d951b;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding: 2rem 2.25rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.repairs .repairs-content {
  background-color: #fff;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 1rem 2.25rem 2.625rem;
}

@media (width <= 900px) {
  .repairs .repairs-content {
    flex-direction: column;
    padding: 1rem 1rem 2rem;
  }
}

.col {
  flex-direction: column;
  flex-shrink: 0;
  width: 32%;
  display: flex;
}

.col h3 {
  color: #1b961b;
  letter-spacing: 2px;
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 1rem;
  font-weight: 600;
  line-height: 1.3;
  display: flex;
}

.col ul {
  flex-direction: column;
  display: flex;
}

.col .offer-list li {
  background: #fafafa;
  height: 2.5rem;
  padding: .25rem;
}

.col .offer-list li:nth-child(odd) {
  background: #efefef;
}

.col li {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-size: .875rem;
  display: flex;
}

@media (width <= 900px) {
  .col {
    width: 100%;
  }
}

.card {
  border-radius: 1.25rem;
  width: 23%;
  overflow: hidden;
}

.card .card-header {
  text-align: center;
  gap: .875rem;
  padding: 2.225rem 2rem;
}

.card .card-header.primary {
  background-color: #1d951b;
}

.card .card-header.secondary {
  background-color: #d3852f;
}

.card .card-header .top-line {
  color: #fff;
}

.card .card-header .top-line span, .card .card-header .top-line h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.card .card-header .top-line span {
  font-size: .75rem;
}

.card .card-header .top-line h3 {
  font-size: 1.125rem;
}

.card .price {
  justify-content: center;
  align-items: center;
  gap: .75rem;
}

.card .price .separator, .card .price .lead {
  letter-spacing: 3px;
  font-size: 1.7rem;
  font-weight: 700;
}

.card .price .asterix {
  letter-spacing: 1px;
  font-size: .65rem;
  font-weight: 700;
}

.card .card-content {
  background-color: #fbfbfb;
  padding: 2.225rem 1.25rem;
}

.card .card-content ul {
  gap: .75rem;
}

.card .card-content ul.primary li:before {
  border-color: #1d951b;
}

.card .card-content li {
  letter-spacing: 1px;
  color: #000;
  padding-left: 1.5rem;
  font-size: .85rem;
  font-weight: 500;
  display: flex;
  position: relative;
}

.card .card-content li:before {
  content: "";
  border: 2px solid;
  border-radius: 50%;
  align-self: center;
  width: .35rem;
  height: .35rem;
  display: flex;
  position: absolute;
  left: 0;
}

@media (width <= 900px) {
  .card {
    width: 100%;
  }

  .card .card-content {
    padding: 2rem 1rem;
  }
}

.line .circle {
  color: #fff;
  background: #1d951b;
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 51px;
  font-family: Audiowide;
  font-size: 1.5rem;
  display: flex;
  position: relative;
}

.line .circle:after {
  content: "";
  background: #1d951b;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  position: absolute;
}

.line .circle:before {
  content: "";
  background: #1d951b;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
}

.line .text {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
}

.line .text span {
  color: #d3852f;
}

@media (width <= 900px) {
  .line .circle:after, .line .circle:before {
    display: none;
  }
}

.bikes .intro-image {
  background: url("bikes.da75295e.jpg") center no-repeat;
  width: 100%;
  height: 389px;
  position: relative;
  overflow: hidden;
}

.bikes .question {
  color: #1b961b;
  justify-content: center;
  font-size: 2rem;
  font-weight: 700;
}

.bikes .points-container {
  width: 80%;
  margin-left: auto;
}

.bikes .points-container .line:nth-child(odd) {
  padding-left: 1.75rem;
}

.bikes .points-container .line:nth-child(odd) .circle:after {
  bottom: -70%;
  right: -5%;
}

.bikes .points-container .line:nth-child(odd) .circle:before {
  bottom: -130%;
  right: 35%;
}

.bikes .points-container .line:nth-child(2n) .circle:after {
  bottom: -80%;
  left: 15%;
}

.bikes .points-container .line:nth-child(2n) .circle:before {
  bottom: -130%;
  left: 55%;
}

.bikes .points-container .line:last-child .circle:before, .bikes .points-container .line:last-child .circle:after {
  display: none;
}

@media (width <= 900px) {
  .bikes .intro-image {
    height: 200px;
  }

  .bikes .points-container {
    width: 100%;
    gap: 2rem !important;
  }

  .bikes .points-container .line:nth-child(odd) {
    padding-left: 0;
  }
}

.title-section {
  color: #fff;
  z-index: 100;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-family: Audiowide;
  font-size: 2.5rem;
  position: absolute;
  inset: 0;
}

.title-section:after {
  content: "";
  z-index: -1;
  background-color: #00000080;
  position: absolute;
  inset: 0;
}

@media (width <= 900px) {
  .title-section {
    text-align: center;
    padding-inline: 2rem;
    font-size: 2rem;
  }
}

.transport .intro-image {
  background: url("transport.de24c8f3.jpg") center no-repeat;
  width: 100%;
  height: 462px;
  position: relative;
  overflow: hidden;
}

@media (width <= 900px) {
  .transport .intro-image {
    height: 200px;
  }
}

.block {
  flex: 1;
  padding-block: 3.5rem;
}

.block .block-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
}

.block p {
  text-align: center;
  flex-grow: 1;
  align-items: center;
  padding-inline: min(6%, 150px);
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.block.green-block {
  background: #1b961b;
}

.block.green-block .block-title, .block.green-block p {
  color: #fff;
}

.block.whtie-block {
  background: #fff;
}

.block.whtie-block .block-title {
  color: #1b961b;
}

.block.whtie-block p {
  color: #787878;
}

.contact {
  padding-block: 3rem;
}

.contact .map-wrapper {
  width: auto;
}

.contact .map-wrapper img {
  width: 100%;
  display: flex;
}

@media (width <= 900px) {
  .contact .contact-mobile-gap {
    gap: 2rem;
  }
}

.dark-part {
  background: #282828;
}

.dark-part .title {
  color: #fff;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
}

.dark-part .sub-title {
  color: #d6d6d6;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}

.dark-part p {
  color: #818181;
  text-align: center;
  font-size: 1.1rem;
}

@media (width <= 900px) {
  .flex-container {
    flex-direction: column;
  }
}

@media (width <= 600px) {
  header ul {
    flex-direction: column;
  }

  #home .intro-image {
    display: none;
  }

  .full-height-section {
    min-height: 50vh;
  }
}
/*# sourceMappingURL=index.b536cfc1.css.map */
