.button {
    border-radius: 50vw;
    border: none;
    outline: none;
    padding: 0.875rem 2.5rem;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 600;

    &.main {
        background-color: #1D951B;
        color: #fff;
    }

    &.secondary {
        background-color: #fff;
        color: #1D951B;
    }

    &:hover {
        cursor: pointer;
    }
}