header {
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--white-color);
    box-shadow: var(--box-shadow-color);
    z-index: 1000;
    padding: 1rem 0;

    nav {
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 2rem;

        ul {
            gap: 1.5rem;
        }

        li a {
            text-decoration: none;
            color: var(--text-color);
            font-size: 1.1rem;
            font-weight: 600;

            &.active {
                color: #1D951B;
            }
        }
    }

    .logo img {
        width: 100px;
    }
}

.menu {
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.hamburger {
    display: none; // Domyślnie ukryty
    cursor: pointer;

    span {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        background-color: #333;
    }

    &:focus {
        outline: none;
    }
}

@media (max-width: 900px) {
    .hamburger {
        display: block; // Pokaż na małych ekranach
    }

    header {
        nav {
            justify-content: space-between;
        }

        ul {
            flex-direction: column;
        }
    }

    .menu {
        display: none; // Domyślnie ukryj menu
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--white-color);

        li {
            text-align: center;
            padding: 10px 0;
        }
    }

    .menu.active {
        display: flex; // Pokaż, gdy menu jest aktywne
        box-shadow: var(--box-shadow-color);
        padding-bottom: 2rem;
    }
}