.flex {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-shrink-0 {
    flex-shrink: 0;
}

.flex-1 {
    flex: 1;
}

.w-50 {
    width: 50%;

    @media(max-width: 900px) {
        width: 100%;
    }
}

.main-container {
    width: 80%;
    margin: auto;

    @media(max-width: 900px) {
        width: 100%;
        padding-inline: 2rem;
    }
}

.justify-content-center {
    justify-content: center;
}


.align-items-center {
    align-items: center;
}


.m-column-direction {
    @media(max-width: 900px) {
        flex-direction: column;
    }
}

.mobile-only {
    display: none;

    @media(max-width: 900px) {
        display: flex;
    }
}


.gap-1 {
    gap: .25rem !important;
}

.gap-3 {
    gap: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.gap-5 {
    gap: 2rem !important;
}

.p-5 {
    padding: 2rem;
}

.mb-8 {
    margin-bottom: 3.5rem;
}

.py-9 {
    padding-block: 4rem;
}

.pb-9 {
    padding-bottom: 4rem;
}

.mb-9 {
    margin-bottom: 4rem;
}

.gap-10 {
    gap: 4.5rem !important;
}

.py-10 {
    padding-block: 4.5rem;
}